import React, { useState } from 'react';
import './AttachmentsTab.css'; // You'll need to create this CSS file

const AttachmentsTab = ({setSelectedFiles}) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleFileChange = (event) => {
    setLoading(true);
    setSelectedFiles([...files, ...event.target.files]);
    setFiles([...files, ...event.target.files]);
    setLoading(false);
  };

  return (
    <div className="attachments-tab">
      <div className="attachments-header">
        <h2>Attachments</h2>
        <button className="upload-button" onClick={() => document.getElementById('file-input').click()}>
          Upload Files
        </button>
        <input
          id="file-input"
          type="file"
          multiple
          onChange={handleFileChange}
          style={{ display: 'none' }}
          disabled={loading}
        />
      </div>
      
      {files.length === 0 ? (
        <div className="no-attachments">
          <div className="icon">📎</div>
          <p>No attachments</p>
          <p>Click the "Upload Files" button to add attachments!</p>
        </div>
      ) : (
        <ul className="file-list">
          {files.map((file, index) => (
            <li key={index}>{file.name}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AttachmentsTab;