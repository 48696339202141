import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import debounce from 'lodash.debounce';
import './AssessmentDetails.css';
import Toolbar from './Toolbar';
import ScoreField from './ScoreField';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import AssessmentCloneModal from './AssessmentCloneModal';


const apiUrl = process.env.REACT_APP_BACKEND_URL;

function AssessmentDetails() {

  const { assessmentId } = useParams();
  const [assessment, setAssessment] = useState(null);
  const [students, setStudents] = useState([]);
  const [error, setError] = useState('');
  const [collapsedCards, setCollapsedCards] = useState({});
  const [activeTabs, setActiveTabs] = useState({});
  const [expandedCard, setExpandedCard] = useState(null); // Track only the expanded card
  const [isSaving, setIsSaving] = useState(false);
  const [pendingChanges, setPendingChanges] = useState([]); // Track changes
  const [grades, setGrades] = useState([]);
  const [showSaved, setShowSaved] = useState(false);
  const [sortCriteria, setSortCriteria] = useState({ field: 'id', order: 'asc' });
  const navigate = useNavigate();
  const [uploadProgress, setUploadProgress] = useState({});
  const [isCloneModalOpen, setIsCloneModalOpen] = useState(false);
  const [currentClassId, setCurrentClassId] = useState(0);
  const [semesterId, setSemesterId] = useState(null);
  const location = useLocation();
  const currentClass = location.state?.currentClass;
  
  useEffect(() => {
    if (currentClass) {
      setCurrentClassId(currentClass.id);
      setSemesterId(currentClass.semesters_id);
    }
  }, [currentClass]);


  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this assessment?')) {
      try {
        const token = localStorage.getItem('jwtToken');
        await axios.delete(`${apiUrl}/api/tasks/${assessmentId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        navigate(-1); // Go back to the previous page after successful deletion
      } catch (error) {
        console.error('Error deleting assessment:', error);
        setError('Failed to delete assessment. Please try again.');
      }
    }
  };

  useEffect(() => {
    if (!isSaving) {
      setShowSaved(true);
      const timer = setTimeout(() => {
        setShowSaved(false);
      }, 1000);

      // Cleanup function to clear the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [isSaving]);

  useEffect(() => {
    const fetchAssessmentDetails = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${apiUrl}/api/tasks/${assessmentId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        response.data.task = {
          ...response.data.task,
          date: response.data.due_date,
          tag: response.data.tag,
        };
        setAssessment(response.data.task);
        setCurrentClassId(response.data.task.classes_id);
        let students_new = [];
        for (let x = 0; x < response.data.students.length; x++) {
          let cont;
          let comment;
          let attachment;
          for (const y of response.data.grades) {
            if (y.student_id == response.data.students[x].id) {
              cont = y.content;
              comment = y.comment;
              attachment = y?.attachment_url ? JSON.parse(y.attachment_url) : [];
            }
          }
          students_new.push({
            ...response.data.students[x],
            grade: cont,
            comment: comment,
            attachments: attachment,
          })
        }
        setStudents(students_new);
        setGrades(response.data.grades);
        const initialCollapsedState = response.data.students.reduce((acc, student) => {
          acc[student.id] = true;
          return acc;
        }, {});
        setCollapsedCards(initialCollapsedState);
      } catch (error) {
        console.error('Error fetching assessment details:', error);
        setError('Failed to fetch assessment details. Please try again.');
      }
    };

    fetchAssessmentDetails();
  }, [assessmentId]);

  const debounceSaveChanges = useCallback(
    debounce(async (changes, assessment) => {
      try {
        setIsSaving(true);
        const token = localStorage.getItem('jwtToken');
        changes.forEach(async element => {
          await axios.post(`${apiUrl}/api/grades`, {
            ...element,
          }, {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          });
        });
       
        setPendingChanges([]); // Clear pending changes after successful save
      } catch (error) {
        console.error('Error saving grades:', error);
        setError('Failed to save grades. Please try again.');
      } finally {
        setIsSaving(false);
      }
    }, 1000), [] // Adjust the debounce delay as needed
  );

  useEffect(() => {
    if (pendingChanges.length > 0) {
      debounceSaveChanges(pendingChanges, assessment);
    }
  }, [pendingChanges, assessment, debounceSaveChanges]);

  const toggleCollapse = (studentId) => {
    setExpandedCard(prevState => prevState === studentId ? null : studentId);
  };

  const handleTabChange = (studentId, tab) => {
    setActiveTabs(prevState => ({
      ...prevState,
      [studentId]: tab,
    }));
  };

  const sortStudents = (field, order) => {
    const sortedStudents = [...students].sort((a, b) => {
      if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    setStudents(sortedStudents);
  };

  useEffect(() => {
    sortStudents(sortCriteria.field, sortCriteria.order);
  }, [sortCriteria]);

  const handleGradeChange = (studentId, grade) => {
    if (!grade) {
      return;
    }
    setStudents(students.map(student => 
      student.id === studentId ? { ...student, grade } : student
    ));
    setPendingChanges(prevChanges => {
      const updatedChanges = prevChanges.filter(change => change.id !== studentId);
      return [...updatedChanges, { student_id: studentId, content:grade.toString(), task_id: assessment.id, class_id: assessment.classes_id}];
    });
  };

  const handleCommentChange = (studentId, comment) => {
    setStudents(students.map(student => 
      student.id === studentId ? { ...student, comment } : student
    ));
  };

  const handleCommentBlur = (studentId) => {
    const student = students.find(student => student.id === studentId);
    if (student && student.comment !== undefined) {
      setPendingChanges(prevChanges => {
        const updatedChanges = prevChanges.filter(change => change.id !== studentId);
        return [...updatedChanges, { student_id: studentId, comment: student.comment, task_id: assessment.id, class_id: assessment.classes_id }];
      });
    }
  };

  const handleAttachmentChange = async (student, studentId, files) => {
    const uploadedFiles = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        setUploadProgress(prev => ({ ...prev, [studentId]: 0 }));
        const token = localStorage.getItem('jwtToken');
        const response = await axios.post(`${apiUrl}/api/upload/file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadProgress(prev => ({ ...prev, [studentId]: percentCompleted }));
          }
        });
        uploadedFiles.push({
          filename: file.name,
          url: `https://storage.cloud.google.com/mgm-bucket-123/${response.data.user_id}/${file.name}`,
          type: file.type
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file. Please try again.');
      }
    }
    
    if (uploadedFiles.length > 0) {
      setStudents(prevStudents => prevStudents.map(student => 
        student.id === studentId 
          ? { ...student, attachments: [...(student.attachments || []), ...uploadedFiles]} 
          : student
      ));

      // Send the uploaded file information to the debounce function
      setPendingChanges(prevChanges => {
        const updatedChanges = prevChanges.filter(change => change.student_id !== studentId || change.type !== 'attachment');
        return [...updatedChanges, {
          student_id: studentId,
          task_id: assessment.id,
          class_id: assessment.classes_id,
          attachment_url: JSON.stringify(uploadedFiles.concat(student.attachments))
        }];
      });
    }
  };

  const FilePreview = ({ file }) => {
    if (file.type.startsWith('image/')) {
      return <img src={file.url} alt={file.filename} style={{ maxWidth: '100px', maxHeight: '100px' }} />;
    } else if (file.type === 'application/pdf') {
      return (
        <Document file={file.url}>
          <Page pageNumber={1} width={100} />
        </Document>
      );
    } else {
      return <span>{file.filename}</span>;
    }
  };

  const saveChanges = async () => {
    try {
      setIsSaving(true);
      const token = localStorage.getItem('jwtToken');
      const promises = pendingChanges.map(element =>
        axios.post(`${apiUrl}/api/grades`, {
          ...element,
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        })
      );
      await Promise.all(promises);
      setPendingChanges([]); // Clear pending changes after successful save
      setShowSaved(true);
      setTimeout(() => setShowSaved(false), 1000);
    } catch (error) {
      console.error('Error saving grades:', error);
      setError('Failed to save grades. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const handleCloneClick = () => {
    setIsCloneModalOpen(true);
  };

  return (
    <div className="assessment-details">
      <Toolbar/>
      {error && <p className="error-message">{error}</p>}
      {assessment && (
        <div className="header-asd">
          <IconButton className="backButton" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <div className="titleDiv">
            <h1>{assessment.name}</h1>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <span className="date">{new Date(assessment.date).toLocaleDateString()}</span>
              <span className="tag">{assessment.type}</span>
            </div>
          </div>
          <div className="moreActions">
            <div className="buttonSavingContainer">
              <Button
              className="btnSpan"
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={saveChanges}
              disabled={pendingChanges.length === 0 || isSaving}
            >
              <span className="savingSpan">{pendingChanges.length === 0 || isSaving ? <>Save</> : <>Saving...</>} </span>
            </Button>
            </div>
          <div className="tooltip" style={{display: 'inline-block', marginLeft: "10px"}}>
            <IconButton onClick={handleCloneClick} color="primary">
              <ContentCopyIcon />
            </IconButton>
            <span className="tooltiptext">Copy to another class</span>
          </div>
          <div className="tooltip" style={{display: 'inline-block', marginLeft: "10px"}}>
            <IconButton onClick={handleDelete} color="error">
              <DeleteIcon />
            </IconButton>
            <span className="tooltiptext">Delete this assessment</span>
          </div>
          </div>
        </div>
      )}
      <div className="controls">
        <div className="sort-controls">
          <label htmlFor="sortField">Sort Students By:</label>
          <select id="sortField" value={sortCriteria.field}
                  onChange={(e) => setSortCriteria({ ...sortCriteria, field: e.target.value })}>
            <option value="id">Student ID</option>
            <option value="name">Student Name</option>
          </select>
          <select id="sortOrder" value={sortCriteria.order}
                  onChange={(e) => setSortCriteria({ ...sortCriteria, order: e.target.value })}>
            <option value="asc">Low-High</option>
            <option value="desc">High-Low</option>
          </select>
        </div>
        <button className="bulk-grade-button" disabled={true} style={{opacity:"50%"}}>Bulk Grade - coming soon!</button>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div className="students-container">
        <div key="abc" className="student-card">
          <div className="student-header" style={{ fontWeight: "bold", color: "gray", paddingTop: "10px", paddingBottom: "10px" }}>
            <span>STUDENT</span>
            <span>EVALUATION</span>
          </div>
        </div>
        {students.map(student => {
          const activeTab = activeTabs[student.id] || 'comments';

          return (
            <div key={student.id} className="student-card">
              <div className="student-header" onClick={() => toggleCollapse(student.id)}>
                <span className="student-avatar">{student.name.split(' ').map(n => n[0]).join('')}</span>
                <span className="student-name">{student.name}</span>
                <ScoreField
                  studentId={student.id}
                  score={student.grade}
                  maxScore={assessment.max_score}
                  isBinary={assessment.type=="Binary"}
                  onScoreChange={handleGradeChange}
                />
              </div>
              {expandedCard === student.id && (
                <div className="student-details">
                  <div className="tabs-ad">
                    <button 
                      className={`tab ${activeTab === 'comments' ? 'active' : ''}`}
                      onClick={() => handleTabChange(student.id, 'comments')}
                    >
                      Comments
                    </button>
                    <button 
                      className={`tab ${activeTab === 'attachments' ? 'active' : ''}`}
                      onClick={() => handleTabChange(student.id, 'attachments')}
                    >
                      Attachments
                    </button>
                  </div>
                  {activeTab === 'comments' && (
                    <textarea
                      placeholder="Add private comment"
                      value={student.comment || ''}
                      onChange={(e) =>{ handleCommentChange(student.id, e.target.value)}}
                      onBlur={(e) => handleCommentBlur(student.id)}
                    />
                  )}
                  {activeTab === 'attachments' && (
                    <div className="attachments">
                      {/* {uploadProgress[student.id] !== undefined && uploadProgress[student.id] !== 100 && (
                        <progress value={uploadProgress[student.id]} max="100" />
                      )} */}
                      {student.attachments && student.attachments.length > 0 && (
                        <div className="file-previews">
                          {student.attachments.map((attachment, index) => (
                            <div key={index} className="file-preview">
                              <FilePreview file={attachment} />
                              {/* <span>{attachment.filename}</span> */}
                            </div>
                          ))}
                        </div>
                      )}
                      <input
                        type="file"
                        id={`file-upload-${student.id}`}
                        style={{ display: 'none' }}
                        onChange={(e) => handleAttachmentChange(student, student.id, e.target.files)}
                        multiple
                      />
                      <label htmlFor={`file-upload-${student.id}`}>
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          startIcon={<CloudUploadIcon />}
                          disabled={uploadProgress[student.id] !== undefined && uploadProgress[student.id] !== 100}
                        >
                          Upload File
                        </Button>
                      </label>
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        })}
      </div>
      </div>
      <footer className="footer" style={{position:"sticky", width:'100%', bottom:'0px'}}>
        <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
      </footer>
      <AssessmentCloneModal
        isOpen={isCloneModalOpen}
        onClose={() => setIsCloneModalOpen(false)}
        assessment={assessment}
        assessmentId={assessmentId}
        currClassId={currentClassId}
        semesterId={semesterId}
      />
    </div>
  );
}

export default AssessmentDetails;
