// src/ClassHeader.js
import React, { useState } from 'react';
import './ClassHeader.css';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import MobileTabs from './MobileTabs';

function ClassHeader({ classes, currentClass, onClassChange, activeTab, setActiveTab, tabs }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleClassChange = (classId) => {
    onClassChange(classId);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <div className="class-header">
        <div className="class-dropdown" style={{minWidth:"15%"}}>
          <div className="dropdown-header" onClick={toggleDropdown} style={{justifyContent: "space-between"}}>
            <span className="current-class">
              <span className="class-name">{currentClass.name}</span>
              <span className="class-section">{currentClass.semester_name}</span>
            </span>
            {isDropdownOpen ? <FaChevronUp /> : <FaChevronDown />}
          </div>
          {isDropdownOpen && (
            <div className="dropdown-menu">
              {classes.map((cls) => (
                <div
                  key={cls.id}
                  className={`dropdown-item-class ${cls.id === currentClass.id ? 'active' : ''}`}
                  onClick={() => handleClassChange(cls.id)}
                  style={{display: "flex", flexDirection: "column"}}
                >
                  <span className="class-name">{cls.name}</span>
                  <span className="class-section">{currentClass.semester_name}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="tabs desktop-only">
          {tabs.map((tab) => (
            <button
              key={tab}
              className={`tab-button ${activeTab === tab ? 'active' : ''}`}
              onClick={() => setActiveTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <MobileTabs activeTab={activeTab} setActiveTab={setActiveTab} tabs={tabs} />
    </>
  );
}

export default ClassHeader;