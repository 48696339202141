// src/ManageStudentsDropdown.js
import React, { useEffect, useState } from 'react';
import StudentModal from './StudentModal';
import './ManageStudentsDropdown.css';
import axios from 'axios';
import StudentSelectionModal from './StudentSelectionModal';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function ManageStudentsDropdown({ semesterId, onRefresh, allStudents, existingStudents, classId }) {
  const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSelectionModal, setShowSelectionModal] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState([]);
  
  let existingStudentsIds = existingStudents.map(student => student.id);
  let students = allStudents.filter(student => !existingStudentsIds.includes(student.id));

  const handleEnrollExisting = async (selStudents) => {
    if (selStudents.length == 0) {
      setShowSelectionModal(false);
    }
    const token = localStorage.getItem('jwtToken');
    let payload = [];
    for (const student of selStudents) {
      payload.push({
        dont_save_students: true,
        id: student.id,
        name: student.name,
        semesters_id: semesterId,
        class_id: classId
      });
    }
    try {
      await axios.post(`${apiUrl}/api/students/batch`, payload, {headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }},);
    } catch (error) {
      console.error('Error adding student:', error);
      return;
    }
    onRefresh();
    setShowSelectionModal(false);
  };
  const handleStudentSelection = (students) => {
    setSelectedStudents(students);
    console.log("STORED SELECTED STUDENTS ", students);
    setShowSelectionModal(false);
  };
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleAddStudents = (students) => {
    console.log('Added students:', students);
  };

  return (
    <div className="manage-students-dropdown">
      <button className="dropdown-button" onClick={toggleDropdown}>
        Manage Students {isOpen ? '▲' : '▼'}
      </button>
      {isOpen && (
        <ul className="dropdown-menu">
          <li>
            <button className="dropdown-item" 
                  onClick={() => {
                setShowSelectionModal(true);
                setIsOpen(false);
              }}>Enroll Existing Students</button>
          </li>
          <li>
            <button
              className="dropdown-item"
              onClick={() => {
                setShowModal(true);
                setIsOpen(false);
              }}
            >
              Create New Students
            </button>
          </li>
          <li>
            <button className="dropdown-item" disabled={true} >Sync Google Classroom - coming soon!</button>
          </li>
        </ul>
      )}
      {showSelectionModal && (
        <StudentSelectionModal
              onClose={handleEnrollExisting}
              onSelect={handleStudentSelection}
              students={students}
              name={"Enroll"}
        />
      )}
      {showModal && (
        <StudentModal
          onClose={() => setShowModal(false)}
          onRefresh={onRefresh}
          semesterId={semesterId}
          classId={classId}
        />
      )}
    </div>
  );
}

export default ManageStudentsDropdown;
