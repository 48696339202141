// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import Semesters from './Semesters';
import ClassDetails from './ClassDetails';
import Reports from './Reports';
import StudentDetail from './StudentDetail';
import './styles.css';
import AssessmentDetails from './AssessmentDetails';
import Callback from './Callback';
import history from "./utils/history";
import { ToolbarProvider } from './ToolbarContext';
import LandingPage from './LandingPage';
import License from './Licence';
function App() {

  return (
    <ToolbarProvider>
      <Router history={history}> 
        <div className="App">
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/callback" element={<Callback />} />
            <Route path="/semesters" element={<Semesters />} />
            <Route path="/class-details" element={<ClassDetails />} />
            <Route path="/student-detail" element={<StudentDetail />} />
            <Route path="/assessments/:assessmentId" element={<AssessmentDetails />} />
            <Route path="/licence" element={<License />} />

          </Routes>
        </div>
      </Router>
    </ToolbarProvider>
  );
}

export default App;
