// src/AssessmentCard.js
import React from 'react';
import './AssessmentCard.css';
import { useParams, useNavigate } from 'react-router-dom';

function AssessmentCard({ observation, selectedClass, settings, email, taskStats }) {
  console.log(observation, taskStats);
  const navigate = useNavigate();
  const date = new Date(observation.created_at).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });
  
  const handleCardClick = () => {
    navigate(`/assessments/${observation.id}`,  { state: { currentClass: selectedClass, email }} );
  };

  return (
    <div className="assessment-card" onClick={handleCardClick}>
      <div className="card-header">
        <span className="card-date">{date}</span>
        <h2 className="card-title">{observation.name}</h2>
      </div>
      <div className="card-category">
        <p  className={`category-tag `} style={{backgroundColor: `${settings[observation.category_id].color}`, color:'black'}}>{settings[observation.category_id].name} {settings[observation.category_id].weight}%</p>
      </div>
      <div className="card-details">
        {(observation.grading_type === 0) ? <p style={{fontSize:"15px"}}>Summative</p>: <p style={{fontSize:"15px"}}>Formative</p>}
        {("average_score" in taskStats && taskStats.average_score) ? <p>Average: {Math.round(taskStats.average_score / taskStats.max_possible * 100)}% </p> : <p>Average: N/A</p>}
      </div>
      {observation.attachment_url && (
        <div className="card-attachment">
          <span>Attachments present</span>
        </div>
      )}
    </div>
  );
}

export default AssessmentCard;
