import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './AssessmentCloneModal.css';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function AssessmentCloneModal({ isOpen, onClose, assessmentId, assessment, currClassId, semesterId }) {
  const [classes, setClasses] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      fetchClasses();
    }
  }, [isOpen]);

  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`${apiUrl}/api/classes_by_semester_id/${semesterId}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response);
      const filteredClasses = response.data.filter(cls => 
        cls.id !== currClassId && 
        cls.class_settings && 
        JSON.parse(cls.class_settings).hasOwnProperty(assessment.category_id.toString())
      );
      setClasses(filteredClasses);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setError('Failed to fetch classes. Please try again.');
    }
  };

  const handleClassToggle = (classId) => {
    setSelectedClasses(prevSelected =>
      prevSelected.includes(classId)
        ? prevSelected.filter(id => id !== classId)
        : [...prevSelected, classId]
    );
  };

  const handleClone = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(`${apiUrl}/api/tasks/${assessmentId}/clone_to`, {
        destination_class_ids: selectedClasses
      }, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      
      const clonedClassNames = classes
        .filter(cls => selectedClasses.includes(cls.id))
        .map(cls => cls.name);
      
      setSuccessMessage(`Successfully cloned to ${clonedClassNames.join(', ')}`);
      
      setTimeout(() => {
        setSuccessMessage('');
        onClose();
      }, 3000);
    } catch (error) {
      console.error('Error cloning assessment:', error);
      setError('Failed to clone assessment. Please try again.');
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay-clone">
      <div className="modal-content-clone">
        <h2 class="clone-title">Clone Assessment to Classes</h2>
        {error && <p className="error-message-clone">{error}</p>}
        {classes.length === 0 ? (
          <p className="no-classes-message">
            Make sure that the category of your assessment exists in the destination classes.
          </p>
        ) : (
          <div className="class-list-clone">
            {classes.map(cls => (
              <div key={cls.id} className="class-item-clone">
                <input
                  type="checkbox"
                  id={`class-${cls.id}`}
                  checked={selectedClasses.includes(cls.id)}
                  onChange={() => handleClassToggle(cls.id)}
                />
                <label htmlFor={`class-${cls.id}`}>{cls.name}</label>
              </div>
            ))}
          </div>
        )}
        {successMessage && (
          <div className="success-message-clone">
            {successMessage}
          </div>
        )}
        <div className="modal-actions-clone">
          <button onClick={onClose} className="cancelButton">Cancel</button>
          <button className="okButton" onClick={handleClone} disabled={selectedClasses.length === 0}>
            Clone
          </button>
        </div>
      </div>
    </div>
  );
}

export default AssessmentCloneModal;