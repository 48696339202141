// src/Semesters.js
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toolbar from './Toolbar';
import './Semesters.css';
import myImage from '.';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import IconButton from '@mui/material/IconButton';

const DropdownIcon = ({ size = 24, color = '#000' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7 10l5 5 5-5"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function Semesters() {
  const location = useLocation();
  const navigate = useNavigate();
  const { email, semesters: initialSemesters } = location.state || {};
  const [semesters, setSemesters] = useState(
    initialSemesters ? initialSemesters.map(sem => ({ ...sem, collapsed: (initialSemesters.length > 1 ? true: false) })) : []
  );
  const [classes, setClasses] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newSemesterName, setNewSemesterName] = useState('');
  const [newSemesterSchoolYear, setNewSemesterSchoolYear] = useState('');
  const [error, setError] = useState('');
  const [isClassModalOpen, setIsClassModalOpen] = useState(false);
  const [newClassName, setNewClassName] = useState('');
  const [selectedSemesterId, setSelectedSemesterId] = useState(null);
  const [editingClassId, setEditingClassId] = useState(null);
  const [editedClassName, setEditedClassName] = useState('');
  const [isEditing, setIsEditing] = useState(false);

  const refreshSemesters = async() =>{
    const token = localStorage.getItem('jwtToken');

    try {
      const semestersResponse = await axios.get(`${apiUrl}/api/semesters`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      let sems = semestersResponse.data.map(sem => ({ ...sem, collapsed: (semestersResponse.data.length > 1 ? true: false) }));

      setSemesters(sems);
    } catch (semestersError) {
      console.error('Error fetching semesters:', semestersError);
    }
  };
  useEffect(() => {
    refreshSemesters();
  }, []);
  
useEffect(() => {
  if (isModalOpen || isClassModalOpen) {
    document.body.classList.add('modal-open');
  } else {
    document.body.classList.remove('modal-open');
  }
}, [isModalOpen, isClassModalOpen]);

const openClassModal = (semesterId) => {
  setSelectedSemesterId(semesterId);
  setIsClassModalOpen(true);
};

const closeClassModal = () => {
  setIsClassModalOpen(false);
  setNewClassName('');
  setSelectedSemesterId(null);
};

const handleCreateClass = async () => {
  const token = localStorage.getItem('jwtToken');
  const classData = {
    name: newClassName,
    semesters_id: selectedSemesterId,
  };

  try {
    await axios.post(`${apiUrl}/api/classes`, classData, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    // Optionally, update the classes state to include the newly created class
    if (selectedSemesterId) {
      const response = await axios.get(
        `${apiUrl}/api/classes_by_semester_id/${selectedSemesterId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        }
      );
      setClasses((prevClasses) => ({
        ...prevClasses,
        [selectedSemesterId]: response.data,
      }));
    }
    closeClassModal();
  } catch (error) {
    console.error('Error creating class:', error);
    setError('Failed to create class. Please try again.');
  }
};
const toggleCollapse = (id) => {
  if (isModalOpen || isClassModalOpen) return; // Prevent toggling if a modal is open

  setSemesters((prevSemesters) =>
    prevSemesters.map((sem) =>
      sem.id === id ? { ...sem, collapsed: !sem.collapsed } : sem
    )
  );
};

useEffect(() => {
  const expandedSemester = semesters.find((sem) => !sem.collapsed && !classes[sem.id]);

  if (expandedSemester) {
    const fetchClasses = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(
          `${apiUrl}/api/classes_by_semester_id/${expandedSemester.id}`,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
          }
        );

        console.log(response.data);
        setClasses((prevClasses) => ({
          ...prevClasses,
          [expandedSemester.id]: response.data,
        }));
      } catch (error) {
        console.error('Error fetching classes:', error);
        setError('Failed to fetch classes. Please try again.');
      }
    };

    fetchClasses();
  }
}, [semesters, classes]);


  const handleClassClick = (cls, clsList, sem) => {
    navigate('/class-details', { state: { currentClass: {...cls, semester_id: sem.id, semester_name: sem.name}, classes: clsList, email} });
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setNewSemesterName('');
  };

  const handleCreateSemester = async () => {
    const token = localStorage.getItem('jwtToken'); // Assuming you store the token in localStorage
    const semesterData = {
      name: newSemesterName,
      school_year: newSemesterSchoolYear,
    };
  
    try {
      await axios.post(`${apiUrl}/api/semesters`, semesterData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      function timeout(delay) {
        return new Promise( res => setTimeout(res, delay) );
      }
      await timeout(300);
      // GET request to fetch the updated list of semesters
      const response = await axios.get(`${apiUrl}/api/semesters`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

    // Update the semesters state with the newly fetched data
      console.log(response.data);
      let sems = response.data.map(sem => ({ ...sem, collapsed: (sem.name == newSemesterName ? false : true) }));
      setSemesters(sems);
      closeModal();
    } catch (error) {
      console.error('Error creating semester:', error);
      setError('Failed to create semester. Please try again.');
    }
  };

  const handleEditClassName = async (classId, oldName, newName, sem_id) => {
    if (oldName == newName) {
      setEditingClassId(null);
      setEditedClassName('');
      setIsEditing(false);
      return;
    }
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.post(`${apiUrl}/api/classes/${classId}`, { name: newName, semesters_id: sem_id }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });

      setClasses((prevClasses) => {
        const updatedClasses = { ...prevClasses };
        Object.keys(updatedClasses).forEach((semesterId) => {
          updatedClasses[semesterId] = updatedClasses[semesterId].map((cls) =>
            cls.id === classId ? { ...cls, name: newName } : cls
          );
        });
        return updatedClasses;
      });

      setEditingClassId(null);
      setEditedClassName('');
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating class name:', error);
      setError('Failed to update class name. Please try again.');
    }
  };

  return (
    <div className="page-container">
      <Toolbar />
      <div className="content-wrap">
        <div className="semesters-container">
          {error && <p className="error-message">{error}</p>}
          {semesters.map((sem) => (
            <div
              key={sem.id}
              className={`semester-card ${sem.collapsed ? 'collapsed' : 'expanded'}`}
              onClick={() => toggleCollapse(sem.id)}
            >
              <div className="semester-header">
                <div style={{display:"flex", flexDirection: "column", justifyContent: "space-between"}}>
                <span style={{fontWeight:"bold"}}>{sem.name}</span>
                <span style={{fontSize:"14px"}}>{sem.school_year}</span>
                </div>
                <div style={{marginLeft:"20px"}}> <DropdownIcon /> </div>
              </div>
              {!sem.collapsed && (
                <>
                  {classes[sem.id] && (
                    <div className="classes-container">
                      {classes[sem.id].map((cls) => (
                        <div
                          key={cls.id}
                          className="class-card"
                          onClick={() => {handleClassClick(cls, classes[sem.id], sem)}}
                          style={{display:"flex", flexDirection: "column", justifyContent: "space-between", position: "relative"}}
                        >
                          <div className="class-card-header">
                            {editingClassId === cls.id ? (
                              <input
                                type="text"
                                value={editedClassName}
                                onChange={(e) => setEditedClassName(e.target.value)}
                                onClick={(e) => e.stopPropagation()}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    handleEditClassName(cls.id, cls.name, editedClassName, sem.id);
                                  }
                                }}
                                style={{border:"none", maxWidth:"130px"}}
                                className="class-name"
                                autoFocus
                              />
                            ) : (
                              <span className="class-name">{cls.name}</span>
                            )}
                            {editingClassId === cls.id ? (
                              <IconButton
                                className="save-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleEditClassName(cls.id, cls.name, editedClassName, sem.id);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "3px",
                                  transform: "scale(0.7)",
                                }}
                              >
                                <SaveIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                className="edit-icon"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setEditingClassId(cls.id);
                                  setEditedClassName(cls.name);
                                  setIsEditing(true);
                                }}
                                style={{
                                  position: "absolute",
                                  top: "10px",
                                  right: "3px",
                                  transform: "scale(0.7)",
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                          </div>
                          <div className="class-card-body">
                            <span className="enrollment-status">ENROLLED</span>
                            {cls.enrollment_count > 0 ?  <span className="no-students">{cls.enrollment_count} STUDENTS</span> : <span className="no-students">NO STUDENTS</span>}
                        </div>
                      </div>
                      ))}
                      <div className="class-card-button">
                      <button
                      className="new-class-button"
                      onClick={(e) => { e.stopPropagation();  openClassModal(sem.id);}}
                    >
                      + New Class
                    </button></div>
                    </div>
                  )}
                </>
              )}
              {isClassModalOpen && (
                <div className="modal-class-new">
                  <div className="modal-content-class">
                    <h2>Create New Class</h2>
                    <input
                      type="text"
                      value={newClassName}
                      onChange={(e) => setNewClassName(e.target.value)}
                      placeholder="Class Name"
                      className="class-input"
                    />
                    <button className="okButton" onClick={handleCreateClass}>Create</button>
                    <button className="cancelButton" onClick={closeClassModal}>Cancel</button>
                  </div>
                </div>
              )}
            </div>
          ))}
          {semesters.length === 0 && (
            <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: 'calc(100vh - 300px)', // Adjust this value based on your layout
              textAlign: 'center',
              position: 'relative',
              zIndex: 1
            }}>
              <p>Feel free to create a new semester!</p>
              <img 
                src='/assets/teacher-student-line-art.png' 
                alt="Teacher and student line art"
                style={{
                  opacity: 0.5,
                  maxWidth: '100%',
                  height: 'auto',
                  marginBottom: '20px'
                }}
              />
            </div>
          )}
          <div style={{display:"flex", justifyContent:"space-evenly", marginTop: "20px"}}>
          <button className="new-semester-button" onClick={openModal}>
            + New Semester
          </button>
          </div>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content-semester">
            <h2>Create New Semester</h2>
            <input
              type="text"
              value={newSemesterName}
              onChange={(e) => setNewSemesterName(e.target.value)}
              placeholder="Semester Name"
            />
            {/* <div className="date-selectors-container">
              <div className="date-selector">
                <label htmlFor="start-date">Start Date:</label>
                <input
                  type="date"
                  id="start-date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  className="date-input"
                />
              </div>
              <div className="date-selector">
                <label htmlFor="end-date">End Date:</label>
                <input
                  type="date"
                  id="end-date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className="date-input"
                />
              </div>
            </div> */}
             <input
              type="text"
              value={newSemesterSchoolYear}
              onChange={(e) => setNewSemesterSchoolYear(e.target.value)}
              placeholder="School year"
            />
            <button onClick={handleCreateSemester}>Create</button>
            <button onClick={closeModal}>Cancel</button>
          </div>
        </div>
      )}

      <footer className="footer">
        <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Semesters;
