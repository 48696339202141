// src/Toolbar.js
import './Toolbar.css';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useState } from 'react';
import { Avatar, Menu, MenuItem, IconButton } from '@mui/material';
import { useToolbarData } from './ToolbarContext';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import axios from 'axios';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function Toolbar() {
  let navigate = useNavigate();
  let toolbarData = useToolbarData();
  const {logout} = useAuth0();

  if (!toolbarData) {
    toolbarData = {
      user: JSON.parse(localStorage.getItem('user')),
      isAuthenticated: localStorage.getItem('isAuthenticated')
    }
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const isInTrial = localStorage.getItem('isInTrial') == 'true';
  const isPaidUser = localStorage.getItem('isPaidUser') == 'true';
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openSemester = async () => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      try {
        const semestersResponse = await axios.get(`${apiUrl}/api/semesters`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const semesters = semestersResponse.data;
        // Navigate to semesters with loaded data
        navigate('/semesters', { state: { semesters } });
      } catch (semestersError) {
        console.error('Error fetching semesters:', semestersError);
      }
    }
  };
  const helpClick = () => {
    window.open("https://forms.gle/YZh3cSEo3MGJJENUA", '_blank');
  };

  return (
    <div className="toolbar" style={{display:"flex", justifyContent: "space-between", paddingLeft:"20px", paddingRight:"20px",  maxHeight:"50px"}}>
      <div style={{display:"flex" }}>
        <p style={{fontWeight:"bold"}}>MyGradeMaster </p>
        { (isInTrial && !isPaidUser) && <p> - trial </p>}
      </div>
      <div>
      { (["/class-details", "/student-detail"].includes(useLocation().pathname)) && 
          <IconButton 
          onClick={openSemester} 
          color="primary"
          size="small"
          style={{ backgroundColor: '#FFD700', marginRight: '20px'}} // Customize the button style if needed
        >
          <ArrowUpwardIcon />
        </IconButton>}
      <IconButton 
        onClick={helpClick} 
        color="primary"
        style={{ marginRight: '10px'}}
      >
        <HelpOutlineIcon />
      </IconButton>
      <IconButton onClick={handleClick} size="small">
        {(toolbarData.user) ? 
        <Avatar alt={toolbarData.user.name} src={toolbarData.user.picture} sx={{ width: 40, height: 40 }} />
          : <p>Loading..</p>
      }
        </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 30,
              height: 30,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {isInTrial &&
        <MenuItem onClick={() => navigate('/licence', { state: { email: toolbarData.user.email } })} style={{padding:'10px'}}>
          Buy licence
        </MenuItem>
        }
        <MenuItem onClick={() => logout({ returnTo: window.location.origin })} style={{padding:'10px'}}>
          Log out
        </MenuItem>
      </Menu>
      </div>
    </div>
  );
}

export default Toolbar;
