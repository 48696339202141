// src/AssessmentModal.js
import React, { useState } from 'react';
import './AssessmentModal.css';
import StudentSelectionModal from './StudentSelectionModal';
import axios from 'axios';
import AttachmentsTab from './AttachmentsTab';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function AssessmentModal({ onClose, currentClass, onObservationCreated, students, isBinary, settings, setIsDirty}) {
  const [activeTab, setActiveTab] = useState('General');
  const [assessmentName, setAssessmentName] = useState('');
  const [dueDate, setDueDate] = useState(new Date().toISOString().split('T')[0]);
  const [outOf, setOutOf] = useState(10);
  // const [weight, setWeight] = useState(1);
  const [category, setCategory] = useState(settings[0].id);
  const [evaluateAs, setEvaluateAs] = useState('Summative');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const [showStudentSelection, setShowStudentSelection] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState(students);
  // const [isExtraCredit, setIsExtraCredit] = useState(false);


  const handleAddStudents = () => {
    setShowStudentSelection(true);
  };

  const handleStudentSelection = (students) => {
    setSelectedStudents(students);
    setShowStudentSelection(false);
  };

  const uploadFiles = async () => {
    if (!selectedFiles) return null;

    let file_ids = [];
    for (const file of selectedFiles) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.post(`${apiUrl}/api/upload/file`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        file_ids.push(response.data.id);
      } catch (error) {
        console.error('Error uploading file:', error);
        alert('Failed to upload file. Please try again.');
        return null;
      }
    }
    return file_ids;
  };

  const onScoreCreated = async () => {
    setIsDirty(true);
    onObservationCreated()
  };

  const handleCreateAssessment = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading

    const fileId = await uploadFiles();

    if (!fileId && selectedFiles) {
      setLoading(false); // Stop loading if file upload fails
      return; // Stop submission if the file failed to upload and a file was selected
    }
    console.log({
          assessmentName,
          dueDate,
          outOf,
          category,
          evaluateAs,
          selectedFiles
          // isExtraCredit,
        });
    const selectedStudentIds = selectedStudents.map(student => student.id);

    let scoreData = {
      name: assessmentName,
      date: dueDate,
      max_score: !isBinary ? outOf: 1,
      classes_id: currentClass.id,
      type: !isBinary ? 'Score' : 'Binary',
      students: selectedStudentIds,
      category_id: parseInt(category),
      grading_type : evaluateAs === "Summative" ? 0 : 1,
      assesment_content: JSON.stringify({students:selectedStudentIds, due_date: dueDate}),
    };
    console.log(JSON.stringify(fileId));
    if (fileId.length > 0) {
      scoreData = {
        ...scoreData,
        attachment_url: `${fileId}`
      }
    } 

    try {
      const token = localStorage.getItem('jwtToken');
      await axios.post(`${apiUrl}/api/tasks`, scoreData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      onScoreCreated(); // Optionally refresh data
      onClose();
    } catch (error) {
      console.error('Error creating observation:', error);
      alert('Failed to create observation. Please try again.');
    } finally {
      setLoading(false); // Stop loading after the request is done
    }
  };

  return (
    <div className="modal-overlay">
      <div className="assessment-modal">
        <div className="modal-header" style={{flexDirection: "column", direction:"flex"}}>
          <div style={{display:"flex", justifyContent: "space-between", width:"100%"}}>
            <h3>Create Assessment</h3>
            <button className="close-button" onClick={onClose}>
              &times;
            </button>
          </div>
          <div style={{display: "flex",  justifyContent: "space-between", width:"100%"}}>
                <div className="input-group" style={{position:"relative", left:0, marginBottom:"10px", width:"65%"}}>
                  <label stlye={{ display: "block"}}>Assessment name</label>
                  <input
                    style={{width:"100%", height:"20px", display: "block"}}
                    type="text"
                    placeholder="Untitled Assessment"
                    value={assessmentName}
                    onChange={(e) => setAssessmentName(e.target.value)}
                  />
                  </div>
                  {!isBinary &&  
                  <div className="input-group input-div-outof">
                    <label>Out of</label>
                    <input
                      type="number"
                      style={{width:"40px", height:"20px"}}
                      value={outOf}
                      onChange={(e) => setOutOf(Number(e.target.value))}
                    />
                  </div>}
                 
                  {/* <div className="input-group" style={{ width:"15%"}}>
                    <label>Weight</label>
                    <input
                      style={{width:"40px", height:"20px"}}
                      type="number"
                      value={1}
                      onChange={(e) => setWeight(Number(e.target.value))}
                    />
                  </div> */}
                </div>
        </div>
        <div className="modal-body">
          <div className="tab-menu-vertical">
            <button
              className={activeTab === 'General' ? 'active' : ''}
              onClick={() => setActiveTab('General')}
            >
              General
            </button>
            {/* <button
              className={activeTab === 'Standards' ? 'active' : ''}
              onClick={() => setActiveTab('Standards')}
            >
              Standards
            </button> */}
            <button
              className={activeTab === 'Attachments' ? 'active' : ''}
              onClick={() => setActiveTab('Attachments')}
            >
              Attachments
            </button>
            {/* <button
              className={activeTab === 'Lessons' ? 'active' : ''}
              onClick={() => setActiveTab('Lessons')}
            >
              Lessons
            </button> */}
          </div>
          <div className="tab-content">
            {activeTab === 'General' && (
              <div style={{display:"flex", flexDirection:"column", padding:"10px"}}> 
                  <label style={{ display: "block", paddingLeft: "10px"}}>Assessing</label>
                  <button
                    type="button"
                    className="add-students-button"
                    style={{ minWidth: "180px", width: "25%", marginTop: "10px", marginBottom: "10px", whiteSpace:"nowrap"}}
                    onClick={handleAddStudents}
                    disabled={loading} // Disable while loading
                  >
                    {selectedStudents.length > 0
                      ? `${selectedStudents.length} Students Selected`
                      : "Add Students"}
                  </button>
                <div>
                <div className="input-group" style={{paddingLeft:"10px"}}>
                  <label>Due Date</label>
                  <input
                    type="date"
                    value={dueDate}
                    className="due-date-class"
                    onChange={(e) => setDueDate(e.target.value)}
                  />
                </div>
                <div className="input-group" style={{paddingLeft:"10px", marginTop: "10px"}}>
                  <label>Category</label>
                  <select
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    style={{ width: "50%", marginBottom: "10px", minWidth:"140px"}}
                  >
                    {settings.map(category =>  <option value={category.id}>{category.name} - {category.weight}%</option>)}
                  </select>
                </div>
                <div className="input-group" style={{paddingLeft:"10px"}}>
                  <label>Evaluate As:</label>
                  <div>
                    <label>
                      <input
                        type="radio"
                        value="Summative"
                        checked={evaluateAs === 'Summative'}
                        onChange={(e) => setEvaluateAs(e.target.value)}
                      />
                      Summative
                    </label>
                    <label>
                      <input
                        type="radio"
                        value="Formative"
                        checked={evaluateAs === 'Formative'}
                        onChange={(e) => setEvaluateAs(e.target.value)}
                      />
                      Formative
                    </label>
                  </div>
                  {/* <label>
                    <input
                      type="checkbox"
                      checked={isExtraCredit}
                      onChange={(e) => setIsExtraCredit(e.target.checked)}
                    />
                    Extra Credit
                  </label> */}
                </div>
                </div>
              </div>
            )}
            {/* {activeTab === 'Standards' && (
              <div className="standards-tab">
                <p>Standards content goes here</p>
              </div>
            )} */}
            {activeTab === 'Attachments' && (
              <AttachmentsTab setSelectedFiles={setSelectedFiles}/>
              // <div className="attachments-tab">
              //   <p>No attachments</p>
              //   <label className="upload-label">
              //     <input type="file" className="upload-input" onChange={handleFileChange} disabled={loading} />
              //     <span>Upload Files</span>
              //   </label>
              // </div>
            )}
            {/* {activeTab === 'Lessons' && (
              <div className="lessons-tab">
                <p>Lessons content goes here</p>
              </div>
            )} */}
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" className="cancel-button" onClick={onClose} disabled={loading}>Cancel</button>
          <button className="create-button" onClick={handleCreateAssessment} disabled={loading}>
          {loading ? 'Saving...' : 'Create'} 
          </button>
        </div>
        {showStudentSelection && (
            <StudentSelectionModal
              onClose={() => setShowStudentSelection(false)}
              onSelect={handleStudentSelection}
              students={students}
              name={"Assess"}
            />
          )}
      </div>
    </div>
  );
}

export default AssessmentModal;
