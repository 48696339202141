// src/CategoriesComponent.js
import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import axios from 'axios';
import './CategoriesComponent.css';

import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';
import DeleteCategoryModal from './DeleteCategoryModal';

ChartJS.register(ArcElement, Tooltip, Legend);

const pieChartColors = [
  "#FF5733", "#33FF57", "#3357FF", "#FFD133", "#33FFF3", 
  "#ffa1eb", "#FF7F33", "#57FF33", "#23A6BF", "#FF33A6"
];

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function CategoriesComponent({ currentClass, markDirty, classes }) {
  const [categories, setCategories] = useState([]);
  const [unsavedChanges, setUnsavedChanges] = useState([]);
  const [saveStatus, setSaveStatus] = useState('');
  const [isCategoryLimitReached, setIsCategoryLimitReached] = useState(false);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSynchronized, setIsSynchronized] = useState(() => {
    if (!classes || classes.length <= 1) return true;
    const firstSettings = classes[0].class_settings;
    return classes.every(cls => cls.class_settings == firstSettings);
  });

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const token = localStorage.getItem('jwtToken');
        const response = await axios.get(`${apiUrl}/api/classes/_settings/${currentClass.id}`, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        const fetchedCategories = response.data || [
          { id: 0, name: 'Homework', weight: 10, color: pieChartColors[0] },
          { id: 1, name: 'Quiz', weight: 30, color: pieChartColors[1] },
          { id: 2, name: 'Test', weight: 40, color: pieChartColors[2] },
          { id: 3, name: 'Assignment', weight: 20, color: pieChartColors[3]},
        ];
        const sortedCategories = [...fetchedCategories].sort((a, b) => a.id - b.id);

        setCategories(sortedCategories);
      } catch (error) {
        console.error('Error fetching categories:', error);
        const sortedCategories = [...JSON.parse(currentClass.class_settings) || []].sort((a, b) => a.id - b.id);
        setCategories(sortedCategories);
      }
    };

    fetchCategories();
  }, [currentClass.id]);

  const addCategory = () => {
    if (categories.length < 10) {
      const newId = categories.length;
      let cats = [...categories, { id: newId, name: 'New Category', weight: 0, color: getUnusedColor(categories) }];
      const sortedCategories = [...cats].sort((a, b) => a.id - b.id);
      setCategories(sortedCategories);
      if (getTotalWeight(categories) === 100) {
        handleSave(categories);
      }
    } 
    if (categories.length === 9) {
      setIsCategoryLimitReached(true);
    }
  };

  const handleCategoryChange = (index, field, value) => {
    const updatedCategories = [...categories];
    updatedCategories[index][field] = field === 'weight' ? parseInt(value) : value;
    setUnsavedChanges([{id: index, field: field, value: value}]);
    setCategories(updatedCategories);
  };

  const getUnusedColor = (usedCategories) => {
    const usedColors = usedCategories.map(category => category.color);
    return pieChartColors.find(color => !usedColors.includes(color)) || pieChartColors[0];
  };

  const handleDeleteCategory = (newCategories, forDeletionIndex, migrateToIndex) => {
    console.log(newCategories, forDeletionIndex, migrateToIndex);
    const sortedCategories = [...newCategories].sort((a, b) => a.id - b.id);
    // Reassign IDs to ensure they match array indices
    const deletedCategoryId = categories[forDeletionIndex].id;
    const migratedToCategoryId = categories[migrateToIndex].id;
  
    const reassignedCategories = sortedCategories.map((category, index) => ({
      ...category,
      id: index,
    }));

    console.log(reassignedCategories);
    // Update the categories state with reassigned IDs
    setCategories(reassignedCategories);

    const migrationData = {
      new_settings: reassignedCategories,
      to_delete: deletedCategoryId,
      migrate_to: migratedToCategoryId
    };

    callMigrate(migrationData);
  };

  const callMigrate =  async (migrationData) => {
    try {
      setSaveStatus('Saving...');
      const token = localStorage.getItem('jwtToken');
     
      // Update the categories state with the new settings from the response
      const response = await axios.post(`${apiUrl}/api/classes/_settings_with_migration/${currentClass.id}`, migrationData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSaveStatus('Saved');
      const updatedCategories = response.data;
      console.log(updatedCategories);
      setCategories(updatedCategories);
      setIsCategoryLimitReached(false);
      setTimeout(() => {setSaveStatus(''); markDirty(true);}, 2000);
    } catch (err) {
      setSaveStatus('Save failed');
      setTimeout(() => setSaveStatus(''), 2000);
    }
  };

  const handleBlur = (index, field, value) => {
    const updatedCategories = [...categories];
    console.log(unsavedChanges);
    if (unsavedChanges.length > 0) {
      for (let x in unsavedChanges) {
          console.log(unsavedChanges[x]);
          if (categories[unsavedChanges[x].id]) {
            updatedCategories[unsavedChanges[x].id][unsavedChanges[x].field] = unsavedChanges[x].field === 'weight' ? parseInt(unsavedChanges[x].value) : unsavedChanges[x].value;
            if (getTotalWeight(updatedCategories) === 100) {
              handleSave(updatedCategories);
            }
          }
      }
    }
    setUnsavedChanges([]);
  };

  const getTotalWeight = (cats) => cats.reduce((sum, category) => sum + category.weight, 0);

  const totalWeight = getTotalWeight(categories);

  const pieData = {
    labels: categories.map(cat => cat.name),
    datasets: [
      {
        data: categories.map(cat => cat.weight),
        backgroundColor: categories.map(cat => cat.color),
      },
    ],
  };

  const handleSave = async (categoriesToSave) => {
    try {
      setSaveStatus('Saving...');
      const token = localStorage.getItem('jwtToken');
      await axios.post(`${apiUrl}/api/classes/_settings/${currentClass.id}`, categoriesToSave, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSaveStatus('Saved');
      markDirty(true);
      setTimeout(() => setSaveStatus(''), 2000);
    } catch (err) {
      setSaveStatus('Save failed');
      setTimeout(() => setSaveStatus(''), 2000);
    }
  };

  const handleSyncSettings = async () => {
    try {
      setIsSyncing(true);
      const token = localStorage.getItem('jwtToken');
      await axios.post(`${apiUrl}/api/classes/_settings`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      setSaveStatus('Settings synchronized');
      setTimeout(() => setSaveStatus(''), 2000);
    } catch (err) {
      setSaveStatus('Synchronization failed');
      setTimeout(() => setSaveStatus(''), 2000);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <div style={{display:"flex", flexDirection: "column", alignItems: "center"}}> 
      <div className="categories-container">
        <div className="categories-list">
          <h3>Categories & Weighting</h3>
          {categories.map((category, index) => (
            <div key={index} className="category-item">
              <div className="category-color" style={{ backgroundColor: category.color }}></div>
              <input
                type="text"
                value={category.name}
                onChange={(e) => handleCategoryChange(index, 'name', e.target.value)}
                onBlur={(e) => handleBlur(index, 'name', e.target.value)}
                className="category-name-input"
              />
              <input
                type="number"
                value={category.weight}
                min={0}
                max={100}
                onChange={(e) => handleCategoryChange(index, 'weight', e.target.value)}
                onBlur={(e) => handleBlur(index, 'weight', e.target.value)}
                className="category-weight-input"
              />
              {
              categories.length > 1 && 
                <DeleteCategoryModal 
                  allCategories={categories.filter(category => (category.id !== index))} 
                  category={category.name} 
                  weight={category.weight} 
                  categoryId={category.id} 
                  handleDeleteCategory={handleDeleteCategory}
                />
              }
            </div>
          ))}
          <button className="add-category-button" id="addCategoryButton" onClick={addCategory}
              disabled={isCategoryLimitReached} hidden={isCategoryLimitReached}>
            + Add Category
          </button>
          <div className="total-weight" style={{marginBottom:"10px"}}>
            {totalWeight !== 100 ? (
              <div className="weight-warning">
                Total weight: {totalWeight}%
              </div>) : (<span>Total weight: {totalWeight}% </span>)
            }
            <br></br>
            {isCategoryLimitReached &&  <div className="weight-warning"><span>Reached max number of categories</span></div>}
          </div>
        </div>
        <div className="pie-chart-container">
          <Pie data={pieData} />
          <p>This will affect all sections of the class.</p>
        </div>
      </div>
    </div>
  );
}

export default CategoriesComponent;
