import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toolbar from './Toolbar';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const License = () => {
  const [licenceKey, setLicenceKey] = useState('');
  const [email, setEmail] = useState('');
  const [isInTrial, setIsInTrial]  = useState(localStorage.getItem('isInTrial') == 'true');
  const [isPaidUser, setIsPaidUser]  = useState(localStorage.getItem('isPaidUser') == 'true');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserEmail = async () => {
      const locationEmail = location.state?.email;
      if (locationEmail) {
        setEmail(locationEmail);
      } else {
        const token = localStorage.getItem('jwtToken');
        if (token) {
          try {
            const response = await axios.get(`${apiUrl}/api/auth/current`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setEmail(response.data.email);
          } catch (error) {
            console.error('Error fetching user data:', error);
            navigate('/');
          }
        } else {
          navigate('/');
        }
      }
    };

    fetchUserEmail();
  }, [location.state, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        `${apiUrl}/api/user/activate-license`,
        { license_key: licenceKey },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('jwtToken')}`,
          },
        }
      );

      if (response.status === 200) {
        try {
            const token = localStorage.getItem('jwtToken');

            const semestersResponse = await axios.get(`${apiUrl}/api/semesters`, {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
              },
            });

            const semesters = semestersResponse.data;
            navigate('/semesters', { state: { email: email, semesters } });
          } catch (semestersError) {
            console.error('Error fetching semesters:', semestersError);
            navigate('/error');
          }
      } else {
        // Handle invalid license key
        alert('Invalid licence key. Please try again or contant our support.');
      }
    } catch (error) {
      console.error('Error submitting license key:', error);
      alert('An error occurred. Please try again.');
    }
  };

  return (
    <div className="page-container">
      <Toolbar />
      <div className="content-wrap">
        <div className="license-container">
        {!isInTrial ? (
            <h2 style={{ textAlign: 'center' }}>
            Your Trial period ended. To continue, please enter the licence key
            </h2>
        ) : (
          <h2 style={{ textAlign: 'center' }}>
          To continue, enter the licence key
        </h2>
        )}
          <form onSubmit={handleSubmit} style={{ textAlign: 'center' }}>
            <div style={{ margin: '20px 0' }}>
              <input
                type="text"
                value={licenceKey}
                onChange={(e) => setLicenceKey(e.target.value)}
                placeholder="Enter your licence key"
                required
                style={{
                  width: '80%',
                  maxWidth: '400px',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '1px solid #ccc',
                  textAlign: 'center',
                  fontSize: '16px',
                }}
              />
            </div>
            <button type="submit">Submit</button>
          </form>
          <p style={{ textAlign: 'center' }}>Don't have a licence key? Purchase one below:</p>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', flexDirection: 'column', alignItems: 'center' }}>
                <table style={{ margin: '20px 0', textAlign: 'center', borderCollapse: 'collapse', width: '80%', maxWidth: '600px' }}>
                <thead>
                    <tr>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f2f2f2' }}>Access Type</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f2f2f2' }}>Price</th>
                    <th style={{ border: '1px solid #ddd', padding: '12px', backgroundColor: '#f2f2f2' }}>Link</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>One year access</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>19$</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>
                        <a href="https://mygrademaster.gumroad.com/l/yearly" target="_blank" rel="noopener noreferrer">
                        <button style={{ padding: '8px 16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Buy Now</button>
                        </a>
                    </td>
                    </tr>
                    <tr>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>Lifetime access</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>49$</td>
                    <td style={{ border: '1px solid #ddd', padding: '12px' }}>
                        <a href="https://mygrademaster.gumroad.com/l/lifetime" target="_blank" rel="noopener noreferrer">
                        <button style={{ padding: '8px 16px', backgroundColor: '#4CAF50', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer' }}>Buy Now</button>
                        </a>
                    </td>
                    </tr>
                </tbody>
                </table>
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    <h2>Why Choose Lifetime Access?</h2>
                    <ul style={{ listStyleType: 'none', padding: '0' }}>
                        <li style={{ margin: '10px 0' }}>✔ One-time payment</li>
                        <li style={{ margin: '10px 0' }}>✔ Unlimited updates</li>
                        <li style={{ margin: '10px 0' }}>✔ Priority support</li>
                    </ul>
                </div>
            </div>     
           </div>

      </div>
      <footer className="footer">
        <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default License;