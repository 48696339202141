import React, { useState } from 'react';
import './ToggleButton.css'; // We'll define styles in a separate CSS file

const ToggleButton = ({ value, onBlur, setScore}) => {
  const [isChecked, setIsChecked] = useState(false);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsChecked(!isChecked);
    setScore((!isChecked) *1);
  };

  return (
    <div 
      className="toggle-container" 
      onClick={handleToggle}
      tabIndex={0}
      onBlur={onBlur}
    >
      <div className={`toggle-option ${value != null && isChecked ? 'active' : ''}`}>
        <span className="checkmark">✓</span>
      </div>
      <div className={`toggle-option ${value != null && !isChecked ? 'active' : ''}`}>
        <span className="cross">✕</span>
      </div>
    </div>
  );
};

export default ToggleButton;