import React, { useEffect, useState } from 'react';
import './DeleteCategoryModal.css'; // Import custom CSS for modal styling

function DeleteCategoryModal({ allCategories, category, categoryId, weight, handleDeleteCategory}) {
  const [show, setShow] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(allCategories[0].id);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSave = () => {
    const unchangedCategories = allCategories.filter(cat => (cat.id != selectedCategory));
    const selectedCat = allCategories.find(cat => cat.id == selectedCategory);
    console.log(allCategories, selectedCategory, selectedCat);

    const updatedSelectedCategory = {
    ...selectedCat,
    weight: selectedCat.weight + weight
    };
    handleDeleteCategory([...unchangedCategories, updatedSelectedCategory], categoryId, selectedCategory);
    
    setShow(false);
  };

  return (
    <>
      <button className="remove-button" onClick={handleShow}>×</button>
      {show && (
        <div className="modal-overlay-delete-category">
          <div className="modal-content-delete-category">
          <div className="modal-delete-head">
            <h2 className="delete-category-title" style={{margin:"0", padding:"10px"}}>Delete Category "{category}"</h2>
            </div>
            <div style={{padding:"20px"}}>
            <div style={{display:"flex", flexDirection: "column"}}>
            <label>
              Where would you like to migrate the assessments from "{category}"?
            </label>
            <select style={{marginTop:"30px", marginBottom:"30px"}}
              value={selectedCategory}
              defaultValue={allCategories[0].id}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
             { allCategories.map(category => <option value={category.id} key={category.id}>{category.name}</option>)}
            </select>
            </div>
            <div className="modal-footer-delete">
              <button className="delete-btn" onClick={handleSave}>
                Delete & Migrate Evaluations
              </button>
              <button className="cancel-btn" onClick={handleClose}>
                Cancel
              </button>
            </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DeleteCategoryModal;
