// src/AssessmentTypeSelectionModal.js
import React from 'react';
import './AssessmentTypeSelectionModal.css';

function AssessmentTypeSelectionModal({ onClose, onSelect }) {
  return (
    <div className="modal-overlay">
      <div className="selection-modal">
        <div className="modal-header">
          <h3>Select an Assessment Type</h3>
          <button className="close-button" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-content">
          <p className="modal-description">Here you can pick what type of assessment to create!</p>
          <ul className="assessment-types">
            <li className="assessment-item">
              <span style={{color:"white", paddingTop:"15px"}}>SCORE</span>
              <button className="assessment-button" onClick={() => onSelect('Score')}>
                <span className="icon">A+</span>
              </button>
            </li>
            <li className="assessment-item">
              <span style={{color:"white", paddingTop:"15px"}}>BINARY</span>
              <button className="assessment-button" onClick={() => onSelect('Binary')}>
                <span className="icon">±</span>
              </button>
            </li>
            {/* <li className="assessment-item">
              <span style={{color:"white", paddingTop:"15px"}}>RUBRIC</span>
              <button className="assessment-button" onClick={() => onSelect('Rubric')}>
                <span className="icon">☑️</span>
              </button>
            </li> */}
            <li className="assessment-item">
              <span style={{color:"white", paddingTop:"15px"}}>OBSERVATION</span>
              <button className="assessment-button" onClick={() => onSelect('Observation')}>
                <span className="icon">👁️</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AssessmentTypeSelectionModal;
