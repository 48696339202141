import React from 'react';
import './LandingPage.css';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const LandingPage = () => {
    const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { loginWithRedirect } = useAuth0();

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${apiUrl}/api/auth/login`,
        {
          email: email,
          password: password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const token = response.data.token;
      localStorage.setItem('jwtToken', token);
      setMessage('Login successful!');

      // Fetch semesters data after successful login
      try {
        const semestersResponse = await axios.get(`${apiUrl}/api/semesters`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });

        const semesters = semestersResponse.data;
        // Navigate to semesters with loaded data
        navigate('/semesters', { state: { email, semesters } });
      } catch (semestersError) {
        console.error('Error fetching semesters:', semestersError);
        setMessage('Error fetching semesters. Please try again.');
      }
    } catch (error) {
      console.error('There was an error!', error);
      setMessage('Login failed. Please try again.');
    }
  };


  return (
    <div className="landing-page" style={{display:"flex", flexDirection:"column"}}>
        <div style={{width:"100%"}}>
        <div className="content">
            <h1>My Grade Master</h1>
            <div className='mobileDescription'>
            <p>Get back your beloved Gradebook <br/>look and feel. </p>
            <p>Achieve everything you could in <br/> your favorite grading app!</p>
            </div>

            <div  className='desktopDescription'>
            <p>Get back your beloved Gradebook look and feel. </p> <br/>
            <p>Achieve everything you could in your favorite grading app!</p>
            </div>
            <button className="start-button" onClick={() => loginWithRedirect()} >START NOW</button>
        </div>
        </div>
        <img 
            className='landing-art'
            src='/assets/teacher-student-line-art.png' 
            alt="MyGradeMaster - line art" 
        />
        <footer className="footer-landing" style={{width:"100%", display: "block"}}>
          <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
        </footer>
   </div>
  );
};

export default LandingPage;