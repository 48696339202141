import React, { useState } from 'react';
import ToggleButton from './ToggleButton';

function ScoreField({ studentId, score, maxScore, onScoreChange, isBinary }) {
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(score || null);

  const handleFocus = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
    if (inputValue !== score) {
      onScoreChange(studentId, inputValue);
    }
  };

  const handleChange = (e) => {
    if (e.target.value < 0) {
        setInputValue(0);
        return;
    }
    if (e.target.value > maxScore) {
        setInputValue(maxScore);    
        return;
    }
    setInputValue(e.target.value);
  };

  return (
    <div className="score-field" >
      {isEditing ? 
      (!isBinary && (
        <input
          type="number"
          value={inputValue}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          className="score-input"
          max={maxScore}
        /> 
      )): (
        !isBinary ? (
        <span
          className="score-display"
          onClick={handleFocus}
          style={{ color: score ? '#4CAF50' : '#000'}} // Change color if score is present
        >
          {score ? `${score} / ${maxScore}` : ` / ${maxScore}`}
        </span>): ( <><ToggleButton
        value={inputValue}
        setScore={setInputValue}
        onBlur={handleBlur}
      />
    </>)
      )}
    </div>
  );
}

export default ScoreField;
