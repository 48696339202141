// src/StudentModal.js
import React, { useState } from 'react';
import axios from 'axios';
import './StudentModal.css';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function StudentModal({ onClose, onRefresh, semesterId, classId }) {
  const [studentData, setStudentData] = useState('');
  const [error, setError] = useState('');

  const handleAddStudents = async () => {
    setError('');
    const students = studentData.split('\n').map(line => {
      const [name, id] = line.split(',').map(item => item.trim());
      return { name, id };
    });

    const invalidStudents = students.filter(student => !student.name);
    if (invalidStudents.length > 0) {
      setError('Each student must have a name.');
      return;
    }

    const token = localStorage.getItem('jwtToken');
    let payload = [];
    for (const student of students) {
      payload.push({
        dont_save_students: false,
        name: student.name,
        semesters_id: semesterId,
        class_id: classId
      });
    }
    try {
      await axios.post(`${apiUrl}/api/students/batch`, payload, {headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            }},);
    } catch (error) {
      console.error('Error adding student:', error);
      setError('Failed to add some students. Please try again.');
      return;
    }

    onRefresh(); // Trigger refresh after adding students
    onClose();
  };

  return (
    <div className="modal-overlay-student">
      <div className="modal-student">
        <div className="modal-header" style={{paddingLeft:"0px"}}>
          <h3>Create New Students</h3>
          <button className="close-button-student" onClick={onClose}>
            &times;
          </button>
        </div>
        <div className="modal-content-student-creation">
          <p>Type in your students' names or copy & paste them in!</p>
          <p>One student per line, with an optional student ID separated with a comma.</p>
          {error && <p className="error-message">{error}</p>}
          <textarea style={{width:"95%"}}
            value={studentData}
            onChange={(e) => setStudentData(e.target.value)}
            placeholder="Name, ID (optional)"
          ></textarea>
        </div>
        <div className="modal-footer" style={{backgroundColor:"white", paddingRight:"0px"}}>
          <button className="cancel-button" onClick={onClose}>Cancel</button>
          <button className="add-button" onClick={handleAddStudents}>Add Students</button>
        </div>
      </div>
    </div>
  );
}

export default StudentModal;
