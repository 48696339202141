import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ObservationModal.css';
import StudentSelectionModal from './StudentSelectionModal';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

function ObservationModal({ onClose, currentClass, students, onObservationCreated, selectedStuds, editingObservation }) {
  const [assesmentContent, setAssesmentContent] = useState('');
  const [showStudentSelection, setShowStudentSelection] = useState(false);
  const [selectedStudents, setSelectedStudents] = useState(selectedStuds);
  const [allStudents, setAllStudents] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [existingFileUrl, setExistingFileUrl] = useState(null);

  // Initialize form with editing data if available
  useEffect(() => {
    if (editingObservation) {
      setAssesmentContent(editingObservation.name);
      const content = JSON.parse(editingObservation.assesment_content);
      setSelectedDate(content.due_date || editingObservation.created_at.split('T')[0]);
      if (editingObservation.attachment_url) {
        setExistingFileUrl(editingObservation.attachment_url);
      }
      
      // Set selected students based on the observation's student list
      const selectedStuds = students.filter(student => 
        content.students.includes(student.id)
      ).map(student => ({ ...student, isSelected: true }));
      setSelectedStudents(selectedStuds);
    }
  }, [editingObservation]);

  const handleAddStudents = () => {
    setShowStudentSelection(true);
  };

  const handleStudentSelection = (students) => {
    setSelectedStudents(students);
    setShowStudentSelection(false);
  };

  useEffect(() => {
    setAllStudents(students.map(student => ({
      ...student,
      isSelected: student.isSelected || false,
    })));
  }, [students]);

  const handleStudentToggle = (id) => {
    setAllStudents(allStudents.map(student =>
      student.id === id ? { ...student, isSelected: !student.isSelected } : student
    ));
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setExistingFileUrl(null); // Clear existing file when new file is selected
  };

  const uploadFile = async () => {
    if (!selectedFile) return existingFileUrl;

    const formData = new FormData();
    formData.append('file', selectedFile);

    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.post(`${apiUrl}/api/upload/file`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      return response.data.id;
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file. Please try again.');
      return null;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const fileId = await uploadFile();

    if (!fileId && selectedFile) {
      setLoading(false);
      return;
    }

    const selectedStudentIds = selectedStudents.map(student => student.id);

    let observationData = {
      name: assesmentContent,
      date: selectedDate,
      classes_id: currentClass.id,
      type: 'observation',
      category_id: 0,
      grading_type: 0,
      assesment_content: JSON.stringify({
        students: selectedStudentIds,
        due_date: selectedDate
      }),
      students: selectedStudentIds,
    };

    if (fileId) {
      observationData.attachment_url = fileId;
    }

    try {
      const token = localStorage.getItem('jwtToken');
      if (editingObservation) {
        // Update existing observation
        await axios.post(`${apiUrl}/api/tasks/${editingObservation.id}`, observationData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
      } else {
        // Create new observation
        await axios.post(`${apiUrl}/api/tasks`, observationData, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
      }
      onObservationCreated();
      onClose();
    } catch (error) {
      console.error('Error saving observation:', error);
      alert('Failed to save observation. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="modal-overlay-observation">
      <div className="modal-content-observation">
        <h2 style={{ width: "50%" }}>{editingObservation ? 'Edit' : 'Add'} Observation</h2>
        <form onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
            <label style={{ paddingRight: "20px", fontSize: "15px" }}>
              <input
                type="date"
                value={selectedDate}
                onChange={(e) => setSelectedDate(e.target.value)}
                required
                className="date-input"
                disabled={loading}
              />
            </label>
            <button
              type="button"
              className="add-students-button"
              style={{ width: "45%" }}
              onClick={handleAddStudents}
              disabled={loading}
            >
              {selectedStudents.length > 0
                ? `${selectedStudents.length} Students Selected`
                : "Add Students"}
            </button>
          </div>

          <label style={{ paddingRight: "20px", marginTop: "10px", fontSize: "15px" }}>
            Comment:
            <textarea
              value={assesmentContent}
              onChange={(e) => setAssesmentContent(e.target.value)}
              required
              className="textarea-input"
              disabled={loading}
            />
          </label>
          <div>Attachments:</div>
          {existingFileUrl && (
            <div style={{ marginBottom: "10px" }}>
              <img src={existingFileUrl} alt="Current attachment" style={{ maxWidth: "100px" }} />
            </div>
          )}
          <label className="upload-label" style={{maxWidth:"80%", width:"80%"}} >
            <input type="file" className="upload-input" onChange={handleFileChange} disabled={loading} />
            {selectedFile ? 
              <span style={{whiteSpace:"nowrap"}}>{selectedFile.name}</span> : 
              <span>{existingFileUrl ? 'Change File' : 'Upload Files'}</span>
            }
          </label>
          <div className="modal-actions">
            <button type="button" className="cancel-button" onClick={onClose} disabled={loading}>
              Cancel
            </button>
            <button type="submit" className="create-button" disabled={loading}>
              {loading ? 'Saving...' : (editingObservation ? 'Update' : 'Create')}
            </button>
          </div>
          {showStudentSelection && (
            <StudentSelectionModal
              onClose={() => setShowStudentSelection(false)}
              onSelect={handleStudentSelection}
              students={students}
              name={"Observe"}
              alreadySelected={selectedStudents}
            />
          )}
        </form>
        {loading && <div className="spinner"></div>}
      </div>
    </div>
  );
}

export default ObservationModal;
