import React from 'react';
import { AssessmentOutlined, PeopleOutlined, SettingsOutlined } from '@mui/icons-material';
import './MobileTabs.css';

function MobileTabs({ activeTab, setActiveTab, tabs }) {
  const getIcon = (tab) => {
    switch (tab) {
      case 'Assessments':
        return <AssessmentOutlined />;
      case 'Students':
        return <PeopleOutlined />;
      case 'Settings':
        return <SettingsOutlined />;
      default:
        return null;
    }
  };

  return (
    <div className="mobile-tabs">
      {tabs.map((tab) => (
        <button
          key={tab}
          className={`mobile-tab-button ${activeTab === tab ? 'active' : ''}`}
          onClick={() => setActiveTab(tab)}
        >
          {getIcon(tab)}
        </button>
      ))}
    </div>
  );
}

export default MobileTabs;
