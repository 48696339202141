// src/ClassDetails.js
import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toolbar from './Toolbar';
import ClassHeader from './ClassHeader';
import ManageStudentsDropdown from './ManageStudentsDropdown';
import AssessmentModal from './AssessmentModal';
import AssessmentTypeSelectionModal from './AssessmentTypeSelectionModal';
import ObservationModal from './ObservationModal';
import CategoriesComponent from './CategoriesComponent';
import AssessmentCard from './AssessmentCard';
import './ClassDetails.css';
import { AssessmentOutlined, PeopleOutlined, SettingsOutlined } from '@mui/icons-material';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function ClassDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentClass, classes, email} = location.state;
  const [activeTab, setActiveTab] = useState('Assessments');
  const [isDirty, setIsDirty] = useState(true);
  const [students, setStudents] = useState([]);
  const [settings, setSettings] = useState(JSON.parse(currentClass.class_settings));
  const [allStudents, setAllStudents] = useState([]);
  const [sortCriteria, setSortCriteria] = useState({ field: 'id', order: 'asc' });
  const [showTypeSelectionModal, setShowTypeSelectionModal] = useState(false);
  const [showBinaryModal, setShowBinaryModal] = useState(false);
  const [showAssessmentModal, setShowAssessmentModal] = useState(false);
  const [showObservationModal, setShowObservationModal] = useState(false);
  const [selectedType, setSelectedType] = useState('');
  const [observations, setObservations] = useState([]);
  const [semesterName, setSemesterName] = useState('');
  const [taskStats, setTaskStats] = useState([]);
  
  const fetchAllStudents = useCallback(async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`${apiUrl}/api/students`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const filteredStudents = response.data.filter(student => student.semesters_id === currentClass.semesters_id);
      setAllStudents(filteredStudents);
      localStorage.setItem("current_students", JSON.stringify(filteredStudents));
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [, currentClass]);

  useEffect(()=>{
    fetchStudents();
    fetchAllStudents();  
    setSemesterName(currentClass.semester_name);
  }, []);

  const fetchStudents = useCallback(async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`${apiUrl}/api/enrolled/`+currentClass.id, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const filteredStudents = response.data.filter(student => student.semesters_id === currentClass.semesters_id);
      setStudents(filteredStudents);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [currentClass.id]);

  useEffect(() => {
    fetchStudents();
  }, [fetchStudents]);

  const unenroll = async (event, studentId) => {
    event.stopPropagation();
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.delete(`${apiUrl}/api/enrolled/${currentClass.id}/${studentId}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const filteredStudents = students.filter(student => (student.semesters_id === currentClass.semesters_id && student.id !== studentId));
      setStudents(filteredStudents);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }

  const fetchObservations = useCallback(async () => {
    if (!isDirty) return;
    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`${apiUrl}/api/tasks/for_class/${currentClass.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      console.log(response.data);
      const classObservations = response.data.tasks.filter(observation => observation.classes_id === currentClass.id && observation.type !== 'observation');
      setSettings(response.data.class_settings);
      setIsDirty(false);
      setObservations(classObservations);
      setTaskStats(response.data.task_stats);
    } catch (error) {
      console.error('Error fetching observations:', error);
    }
  }, [currentClass.id, isDirty]);

  useEffect(() => {
    fetchObservations();
  }, [fetchObservations]);


  useEffect(() => {
    if (activeTab === 'Students') {
      fetchStudents();
      fetchAllStudents();
    }
  }, [activeTab, fetchStudents]);

  const sortStudents = (field, order) => {
    const sortedStudents = [...students].sort((a, b) => {
      if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
      if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    setStudents(sortedStudents);
  };

  useEffect(() => {
    sortStudents(sortCriteria.field, sortCriteria.order);
  }, [sortCriteria]);

  const handleSelectType = (type) => {
    setSelectedType(type);
    setShowTypeSelectionModal(false);
    if (type === 'Score') {
      setShowAssessmentModal(true);
    } else if (type === 'Observation') {
      setShowObservationModal(true);
    } else if (type === 'Binary') {
      setShowBinaryModal(true);
    }
  };

  const tabs = ['Assessments', 'Students', 'Settings'];

  return (
    <div className="class-details">
      <Toolbar/>
      <ClassHeader
        classes={classes}
        currentClass={currentClass}
        onClassChange={(classId) => {
          const selectedClass = classes.find((cls) => cls.id === parseInt(classId, 10));
          if (selectedClass) {
            setIsDirty(true);
            navigate('/class-details', { state: { currentClass: {...selectedClass, semester_name: semesterName}, classes, email} });
          }
        }}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={tabs}
      />
      <div className="content-class">
        {activeTab === 'Assessments' && (
            <div className="assessment-cards-container">
            {observations.map(observation => (
              <AssessmentCard key={observation.id} observation={observation} settings={settings} email={email} taskStats={taskStats[observation.id] || []} selectedClass={currentClass}/>
            ))}
            {(observations.length == 0 && ((students.length == 0) ?
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 300px)',
                width: '100%', // Adjust this value based on your layout
                textAlign: 'center',
                position: 'relative',
                zIndex: 1
              }}>
                <p>Make sure to add some students to this class!</p>
                {/* <p>Feel free to create a new semester!</p> */}
                <img 
                  src='/assets/teacher-two-students.png' 
                  alt="Teacher and student line art"
                  style={{
                    opacity: 0.5,
                    maxWidth: '100%',
                    height: 'auto',
                    marginBottom: '20px'
                  }}
                />
              </div> : 
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: 'calc(100vh - 300px)',
                width: '100%', // Adjust this value based on your layout
                textAlign: 'center',
                position: 'relative',
                zIndex: 1
              }}>
                <p>You can now create some assessments using the + button!</p>
                {/* <p>Feel free to create a new semester!</p> */}
                <img 
                  src='/assets/teacher-two-students.png' 
                  alt="Teacher and student line art"
                  style={{
                    opacity: 0.5,
                    maxWidth: '100%',
                    height: 'auto',
                    marginBottom: '20px'
                  }}
                />
              </div>))
            }
            {!showTypeSelectionModal && (
            <button
              className="add-assessment-button"
              onClick={() => setShowTypeSelectionModal(true)}
            >
              +
            </button>
            )}
            {showTypeSelectionModal && (
              <AssessmentTypeSelectionModal
                onClose={() => setShowTypeSelectionModal(false)}
                onSelect={handleSelectType}
              />
            )}
            {showAssessmentModal && selectedType === 'Score' && (
              <AssessmentModal onClose={() => setShowAssessmentModal(false)} students={students} currentClass={currentClass} onObservationCreated={fetchObservations} isBinary={false} settings={settings} setIsDirty={setIsDirty}/>
            )}
            {showObservationModal && selectedType === 'Observation' && (
              <ObservationModal
                onClose={() => setShowObservationModal(false)}
                currentClass={currentClass}
                students={students}
                onObservationCreated={fetchObservations} 
                showSelection={true}
                selectedStuds={[]}
                // Refresh observations after creation
              />
            )}
            {showBinaryModal && selectedType === 'Binary' && (
              <AssessmentModal onClose={() => setShowBinaryModal(false)} students={students} currentClass={currentClass} onObservationCreated={fetchObservations} isBinary={true} settings={settings} setIsDirty={setIsDirty}/>
            )}
          </div>
        )}
        {activeTab === 'Students' && (
          <div className="student-content">
            <div className="student-controls">
              <div className="sort-controls">
                <label htmlFor="sortField">Sort Students:</label>
                <select
                  id="sortField"
                  value={sortCriteria.field}
                  onChange={(e) => setSortCriteria({ ...sortCriteria, field: e.target.value })}
                >
                  <option value="id">Student ID</option>
                  <option value="name">Name</option>
                </select>
                <select
                  id="sortOrder"
                  value={sortCriteria.order}
                  onChange={(e) => setSortCriteria({ ...sortCriteria, order: e.target.value })}
                >
                  <option value="asc">Low-High</option>
                  <option value="desc">High-Low</option>
                </select>
              </div>
              <ManageStudentsDropdown
                semesterId={currentClass.semesters_id}
                onRefresh={fetchStudents}
                allStudents={allStudents}
                existingStudents={students}
                classId={currentClass.id}
              />
            </div>
            <ul className="student-list">
              {students.map(student => (
                <li
                  key={student.id}
                  className="student-item"
                  onClick={() => navigate('/student-detail', { state: { student, students, email, currentClass, classes, settings } })}
                >
                  <div className="student-avatar">
                    {student.name.split(' ').map(n => n[0]).join('').toUpperCase()}
                  </div>
                  <div className="student-info">
                    <strong>{student.name}</strong>
                    <p className="student-id-id">ID: {student.id}</p>
                  </div>
                  <div className="student-actions">
                    {/* <button className="edit-button">Edit Student</button> */}
                    <button className="unenroll-button" onClick={(event) => unenroll(event, student.id)}>Unenroll</button>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
        {activeTab === 'Settings' && (
          <CategoriesComponent currentClass={currentClass} markDirty={setIsDirty} classes = {classes}/>
        )}
      </div>
      <footer className="footer" style={{position:"sticky", width:'100%', bottom:'0px'}}>
        <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default ClassDetails;
