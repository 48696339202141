import React, { useEffect} from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Callback = () => {
  const { user, isAuthenticated } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated && user) {
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('isAuthenticated', true);
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    const registerUser = async () => {
      if (isAuthenticated && user) {
        try {
          // Generate a "password" by reversing the email string (or any other logic)
          const randomPassword = user.email.split('').reverse().join('');

          const response = await axios.post(
            `${apiUrl}/api/auth/register`,
            {
              email: user.email,
              password: randomPassword,
              name: user.name || user.email.split('@')[0],
            },
            {
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          const { token, is_paid_user, is_in_trial } = response.data;
          console.log(response.data);
          localStorage.setItem('jwtToken', token);
          localStorage.setItem('isInTrial', is_in_trial);
          localStorage.setItem('isPaidUser', is_paid_user);
          if (is_paid_user || is_in_trial) {
            // Fetch semesters data and navigate to semesters page
            try {
              const semestersResponse = await axios.get(`${apiUrl}/api/semesters`, {
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${token}`,
                },
              });

              const semesters = semestersResponse.data;
              navigate('/semesters', { state: { email: user.email, semesters } });
            } catch (semestersError) {
              console.error('Error fetching semesters:', semestersError);
              navigate('/error');
            }
          } else {
            // Navigate to the license page if user is not paid and not in trial
            navigate('/licence', { state: { email: user.email } });
          }
        } catch (error) {
          console.error('Error registering user:', error);
          navigate('/error');
        }
      }
    };

    registerUser();
  }, [isAuthenticated, user, navigate]);

  return <div>Loading...</div>;
};

export default Callback;
