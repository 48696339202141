// ToolbarContext.js
import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ToolbarContext = createContext();

export const ToolbarProvider = ({ children }) => {
  const [toolbarData, setToolbarData] = useState(null);
    const { user, isAuthenticated, logout } = useAuth0();

  useEffect(() => {
    // Fetch data once and store in state

    setToolbarData({user, isAuthenticated, logout});
  }, [user]);

  return (
    <ToolbarContext.Provider value={toolbarData}>
      {children}
    </ToolbarContext.Provider>
  );
};

export const useToolbarData = () => useContext(ToolbarContext);
