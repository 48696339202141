import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Toolbar from './Toolbar';
import ClassHeader from './ClassHeader';
import './StudentDetail.css';  // Import your CSS file for styling
import PrintIcon from '@mui/icons-material/Print';
import ObservationModal from './ObservationModal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

function StudentDetail() {
  const location = useLocation();
  const { student = {}, email, students = [], currentClass, classes, settings} = location.state || {};
  console.log(students);

  const navigate = useNavigate();
  const [observations, setObservations] = useState([]);
  const [grades, setGrades] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [studentName, setStudentName] = useState('');
  const [rates, setRates] = useState([]);
  const [average, setAverage] = useState(0);
  const [combinedTimeline, setCombinedTimeline] = useState([]);

  const [studentBio, setStudentBio] = useState('');

  const [showObservationModal, setShowObservationModal] = useState(false);
  const [editingObservation, setEditingObservation] = useState(null);

  useEffect(() => {
    setStudentName(student.name);
    setStudentBio(student.bio || '');
  }, [student]);

  const handleBioChange = (e) => {
    setStudentBio(e.target.value);
  };

  const handleBioBlur = async () => {
    const token = localStorage.getItem('jwtToken');
    try {
      await axios.post(`${apiUrl}/api/students/${student.id}`, {
        name: studentName,
        bio: studentBio,
        semesters_id: student.semesters_id
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
    } catch (error) {
      console.error('Error updating student bio:', error);
    }
  };

  useEffect(() => {
    setStudentName(student.name);
  }, [student]);

  const fetchStudentObservations = useCallback(async () => {
    setRates([]);
    setAverage(null);

    const token = localStorage.getItem('jwtToken');
    try {
      const response = await axios.get(`${apiUrl}/api/tasks`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      const studentObservations = response.data.filter(
        (observation) => (observation.classes_id == currentClass.id && observation.type == "observation")
          && JSON.parse(observation.assesment_content).students.includes(student.id)
      );

      setObservations(studentObservations);

      const response2 = await axios.get(`${apiUrl}/api/tasks/${currentClass.id}/${student.id}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
      });
      setGrades(response2.data);
      // Calculate total grade based on settings and grades
      let totalGrade = 0;
      let totalWeight = 0;
      if (settings && settings.length > 0) {
        settings.forEach(category => {
          const categoryGrades = response2.data.filter(grade => grade.category_id === category.id && grade.scored);
          let categoryAverage;

          if (categoryGrades.length > 0) {
            const categorySum = categoryGrades.reduce((sum, grade) => sum + (grade.scored / grade.max_score), 0);
            categoryAverage = categorySum / categoryGrades.length;
          } else {
            categoryAverage = 1; // 100% if no tasks in this category
          }

          totalGrade += categoryAverage * category.weight;
          totalWeight += category.weight;
        });

        // Normalize the total grade if the weights don't sum to 100
        if (totalWeight > 0) {
          totalGrade = (totalGrade / totalWeight) * 100;
        }

        setAverage(totalGrade / 100); // Set as decimal for consistency with existing code
      }
    } catch (error) {
      console.error('Error fetching student observations:', error);
    }
  }, [currentClass.id, student]);

  useEffect(() => {
    fetchStudentObservations();
  }, [fetchStudentObservations]);

  useEffect(() => {
    // Combine observations and grades
    const combined = [...observations, ...grades].map(item => ({
      ...item,
      date: JSON.parse(item.assesment_content).due_date || item.created_at,
      type: item.type || 'grade'
    }));

    // Sort by date descending
    const sorted = combined.sort((a, b) => new Date(b.date) - new Date(a.date));

    setCombinedTimeline(sorted);
  }, [observations, grades]);

  const handleEditToggle = async () => {
    if (isEditing) {
      // Save mode
      await handleBioBlur();
      
      const updatedStudent = { ...student, name: studentName, bio: studentBio };
      
      // Update the students array in the parent component
      const updatedStudents = students.map(s => s.id === student.id ? updatedStudent : s);
      
      // Navigate back to the same page with updated state
      navigate('/student-detail', {
        state: {
          student: updatedStudent,
          currentClass,
          classes,
          email,
          students: updatedStudents,
          settings
        },
        replace: true
      });
    }
    setIsEditing(!isEditing);
  };

  const callGenerate = () => {
      console.log("called PDF multi");
      const token = localStorage.getItem('jwtToken');
      axios.post(`${apiUrl}/api/classes/generate_multi_report`, {
          student_id: student.id,
          class_id: currentClass.id
      }, {
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
          },
          responseType: 'blob' // Important for handling file downloads
      })
      .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          const currentDate = new Date().toISOString().split('T')[0];
          link.setAttribute('download', student.name + ' - ' + currentDate + '.pdf');
          document.body.appendChild(link);
          link.click();
          link.remove();
      })
      .catch(error => {
          console.error('Error:', error);
      });
  };

  const handleEditObservation = (observation) => {
    setEditingObservation(observation);
    setShowObservationModal(true);
  };

  const handleDeleteObservation = async (observationId) => {
    if (window.confirm('Are you sure you want to delete this observation?')) {
      const token = localStorage.getItem('jwtToken');
      try {
        await axios.delete(`${apiUrl}/api/tasks/${observationId}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        fetchStudentObservations(); // Refresh the observations list
      } catch (error) {
        console.error('Error deleting observation:', error);
      }
    }
  };

  return (
    <div className="student-detail">
      <Toolbar />
      <ClassHeader
        classes={classes}
        onClassChange={(classId) => {
          const selectedClass = classes.find((cls) => cls.id === parseInt(classId, 10));
          if (selectedClass) {
            navigate('/class-details', { state: { currentClass: {...selectedClass, semester_name: currentClass.semester_name}, classes, email} });
          }
        }}
        currentClass={currentClass}
        activeTab="Students"
        tabs={['Assessments', 'Students', 'Settings']}
        setActiveTab={() => navigate('/class-details', { state: { currentClass: currentClass, classes, email, activeTabInitial: 'Students' } })}
      />
      <div className="acontainer">
        <div className="student-sidebar">
          {students.map((s) => (
            <div
              key={s.id}
              className={`student-initials ${s.id === student.id ? 'active' : ''}`}
              onClick={() => navigate('/student-detail', { state: { student: s, currentClass, classes, email, students, settings } })}
            >
              {s.name.split(' ').map((n) => n[0]).join('').toUpperCase()}
            </div>
          ))}
        </div>
        <div className="student-info-details containers">
          <div className="close-icon" onClick={() => navigate('/class-details', { state: { currentClass: currentClass, classes, email, activeTab: 'Students' } })}>
            &times;
          </div>
          <div className="student-avatar-details">
            {student.name.split(' ').map(n => n[0]).join('').toUpperCase()}
          </div>
          <div className="student-ids">
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={studentName}
                  onChange={(e) => setStudentName(e.target.value)}
                  style={{ fontSize: '1.17em', fontWeight: 'bold', marginTop: '0px', border: "white", borderRadius: "5px", width: '100%' }}
                />
                <textarea
                  value={studentBio}
                  onChange={(e) => setStudentBio(e.target.value)}
                  placeholder="Enter student bio"
                  style={{ width: '100%', marginBottom: '5px', marginTop: '5px', border: "white", borderRadius: "5px", minHeight: '40px', maxHeight: '150px', overflowY: 'auto', paddingTop: '5px', paddingBottom: '5px', resize: 'vertical' }}
                />
              </>
            ) : (
              <>
                <h3 style={{ marginTop: '0px' }}>{studentName}</h3>
                <textarea
                  value={studentBio || 'No bio available'}
                  disabled
                  placeholder="Enter student bio"
                  style={{ width: '100%', marginBottom: '5px', marginTop: '5px', border: "white", borderRadius: "5px", minHeight: '40px', maxHeight: '100px', overflowY: 'auto', paddingTop: '5px', paddingBottom: '5px', resize: 'vertical' }}
                />             
                 </>
            )}
            <p  style={{marginBottom: "5px"}}>ID: {student.id}</p>
            <button onClick={handleEditToggle}>
              {isEditing ? 'Save' : 'Edit Student Info'}
            </button>
            <div className="overall-grade">
              <h4 style={{marginTop: "10px", marginBottom:"10px"}}>Overall Grade</h4>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
                {average ? <p> {(average * 100).toFixed(2)}% </p> : <p>N/A</p>}
                
                <button
                  onClick={() => {
                    callGenerate();
                    const alertBox = document.createElement('div');
                    alertBox.textContent = "Generating and downloading report started...";
                    alertBox.style.position = 'fixed';
                    alertBox.style.top = '10px';
                    alertBox.style.left = '50%';
                    alertBox.style.transform = 'translateX(-50%)';
                    alertBox.style.backgroundColor = 'lightblue';
                    alertBox.style.padding = '10px';
                    alertBox.style.border = '1px solid #333';
                    alertBox.style.zIndex = '1000';
                    document.body.appendChild(alertBox);
                    setTimeout(() => {
                      document.body.removeChild(alertBox);
                    }, 1000);
                   // navigate(`/reports`, { state: { email , students: students}}
                    }}
                  style={{ marginLeft: '10px', marginRight: '10px', background: 'lightblue', border: 'solid 1px #333', cursor: 'pointer', transform: "scale(0.8)" }}
                >
                  <div style={{display: 'flex'}}>
                  <PrintIcon />
                  <p className="student-p" style={{padding:0, margin:0, marginLeft: '5px'}}> Print/PDF </p>
                  </div>
                </button>

              </div>
            </div>
          </div>
        </div>
        <div className="timeline containers">
          <button
            className="add-observation-button"
            onClick={() => setShowObservationModal(true)}
          >
            +
          </button>
          {combinedTimeline.map((item) => (
            <div key={item.id} className="timeline-item">
              <div className="timeline-content">
                {item.type === 'observation' ? (
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                      <span className="">Observation</span>
                      <div>
                        <EditIcon 
                          onClick={() => handleEditObservation(item)}
                          style={{ cursor: 'pointer', fontSize: '20px', marginRight: '8px' }}
                        />
                        <DeleteIcon
                          onClick={() => handleDeleteObservation(item.id)}
                          style={{ cursor: 'pointer', fontSize: '20px', color: '#d32f2f' }}
                        />
                      </div>
                    </div>
                    <div className="timeline-date">
                      {new Date(item.date).toLocaleDateString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </div>
                    {item.name.length > 0 && <p style={{ backgroundColor: "lightyellow", padding: "10px", borderRadius: "5px" }}>{item.name}</p>}
                    <img src={item.attachment_url} style={{ maxWidth: "30%" }} />
                  </>
                ) : (
                  <>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                      <p style={{ marginTop: 0, marginBottom: "10px"}}>{item.name}</p>
                      {item.scored ? <div className="graded-label">{item.scored} / {item.max_score}</div> : <div className="ungraded-label">UNGRADED</div>}
                    </div>
                    <div className="timeline-date">
                      {new Date(item.date).toLocaleDateString('en-US', {
                        weekday: 'short',
                        month: 'short',
                        day: 'numeric',
                      })}
                    </div>
                    <span className="category-tag" style={{backgroundColor:settings[item.category_id].color, color:'black'}}>{settings[item.category_id].name} {settings[item.category_id].weight}%</span>
                    {item.comment && item.comment.length > 0 &&
                      <p style={{ backgroundColor: "lightyellow", padding: "10px", borderRadius: "5px" }}>{item.comment}</p>
                    }
                    <br>
                    </br>
                    <div style={{display:"flex"}}>
                    {item.attachment_url && (
                      JSON.parse(item.attachment_url).map((attachment, index) => (
                        <img 
                          key={index}
                          src={attachment ? attachment.url : ''} 
                          alt={attachment ? attachment.filename : ''} 
                          style={{ maxWidth: "30%", marginTop: "10px", marginRight: "10px" }} 
                        />
                      ))
                    )}
                    </div>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      {showObservationModal && (
        <ObservationModal
          onClose={() => {
            setShowObservationModal(false);
            setEditingObservation(null);
          }}
          currentClass={currentClass}
          students={students}
          onObservationCreated={() => {
            setShowObservationModal(false);
            setEditingObservation(null);
            fetchStudentObservations();
          }}
          showSelection={false}
          selectedStuds={[{...student, isSelected: true}]}
          editingObservation={editingObservation}
        />
      )}
      {/* <footer className="footer" style={{position:"absolute", bottom:"0px", width:"100%"}}>
        <p>&copy; 2024 MyGradeMaster. All rights reserved.</p>
      </footer> */}
    </div>
  );
}

export default StudentDetail;
